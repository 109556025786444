const content: { [key: string]: any } = {
  pageTitle: "Vision Bonus+",
  bannerTile: {
    onTrack: "Keep going to earn the Vision Bonus+!",
    earned: "Great Job! You are on track to earn the Vision Bonus+.",
    onTrackEarnedSubtitle:
      "You must meet the annual requirements consecutively for 2 or 3 years to earn the Vision Bonus+.",
    notTracking: "You are not currently tracking to achieve the Vision Bonus+.",
  },
  progressTile: {
    title: "Track your progress",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
    met: "Vision Bonus Requirement {number} Met",
    notMet: "Tracking Vision Bonus Requirement {number}",
  },
  incentiveCalc: {
    sectionHeader: "How is the incentive calculated?",
    sectionDescription:
      "The first year of qualification for Vision Bonus+ is PY26. The base amount will be based on how much you have earned for FGI or QCGI in PY25 and PY26.",
    iconAriaLabel: "Additional Payment Details",
    fqIncentive: {
      fqGrowth: "FQ Growth",
      base: "FQ Growth Multiplier Base",
      multipler: "FQ Growth Multiplier",
      total: "Potential PY25 FQ Growth Amount",
    },
    qcIncentive: {
      qcGrowth: "QC Growth",
      base: "QC Growth Multiplier Base",
      multipler: "QCGI Multiplier",
      total: "Potential PY25 QC Growth Amount",
    },
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of annual requirements.",
    maintainPinTile: {
      maintainPin: "Maintain Pin",
      achieved: "Achieved",
      notTracking: "Not Tracking",
    },
    fqOrQcGrowthTile: {
      fqOrQcGrowth: "FQ or QC Growth",
      fqGrowth: "FQ Growth",
      qcGrowth: "QC Growth",
      viewDetails: "View Details",
    },
  },
  multiplierBaseModal: {
    title: "PY{performanceYear} Multiplier Base",
    onTrack: "On Track",
    bonus: "Bonus",
    amount: "Amount",
    performanceBonus: "Performance Bonus",
    rubyBonus: "Ruby Bonus",
    leadershipBonus: "Leadership Bonus",
    fosterBonus: "Foster Bonus",
    depthBonus: "Depth Bonus",
    totalBonusLabel: "Total Multiplier Base",
    monthlyCoreBonusTotal: "Monthly Core Bonus Total",
  },
  fqGrowthMultiplierModal: {
    title: "FQ Growth Multiplier",
    fqOverBaseline: "FQ over Basline",
    belowDiamond: "Below Diamond",
    diamondPlus: "Diamond+",
    growMax: "Grow {max}+",
    growRange: "Grow {start}-{end}",
    potentialMultiplier: "Potential Multiplier = {multiplier}",
  },
  qcGrowthMultiplierModal: {
    title: "QC Growth Incentive Multiplier",
    potentialMultiplier: "Potential Multiplier = {multiplier}",
    garAward: "GAR Award",
    qcRange: "{start}-{end} QC",
    qcMax: "{max} QC+",
    executiveDiamond: "Executive Diamond & Founders Executive Diamond",
    doubleDiamond: "Double Diamond & Founders Double Diamond",
    tripleDiamond: "Triple Diamond & Founders Triple Diamond",
    crown: "Crown & Founders Crown",
    crownAmbassador: "Crown Ambassador & Founders Crown Ambassador",
  },
  FqQcStatusModal: {
    title: "FQ/QC Status",
    fqStatus: "FQ Status",
    qcStatus: "QC Status",
    pyFQs: "{performanceYear} FQs",
    pyQCs: "{performanceYear} QCs",
    currentYtd: "Current YTD",
    tracking: "Tracking",
  },
} as const;

export default content;
